@import "../../assets/styles/scss/variables";
@import "../../assets/styles/scss/mixins";
@import "../../assets/styles/scss/typography";

.staff-schedule-leave-card {
  background-color: #fad8d8;
  border: 0.5rem solid white !important;
  border-radius: 18px !important;
}

.staff-schedule-card {
  align-content: start;
  border-radius: 0.5rem !important;
}

.week-day {
  //font-weight: bold;
  font-weight: 500;
  text-transform: capitalize;
}

.staff-schedule {
  height: 120px;
  background-color: #ccf1ce;
  border-bottom: 0.5rem solid white;
  padding: 0.5rem;
  font-weight: 500;

  .shiftName {
    text-transform: capitalize;
  }
  .role {
    font-weight: bold;
    text-transform: capitalize;
  }
  .time {
    text-transform: capitalize;
  }
}

.staff-schedule-dashboard-card {
  background-color: #ccf1ce;
  border: 0.5rem solid #fff !important;
  border-radius: 0.5rem !important;
}

.staff-schedule-dashboard {
  min-height: 35px;
  font-weight: 500;
  padding: 0.5rem;

  .shiftName {
    text-transform: capitalize;
  }
  .role {
    font-weight: bold;
    text-transform: capitalize;
  }
  .time {
    text-transform: capitalize;
  }
}

.work-time-schedule-table {
  thead {
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
    z-index: 2 !important;
  }
  .p-datatable-thead {
    tr {
      th:first-child {
        .p-column-header-content {
          justify-content: center;
        }
      }
    }
  }
  &.p-datatable {
    .p-datatable-thead {
      tr {
        th {
          background: #f1f5f9;
          border-left: 2px solid #fff;
        }
      }
    }
  }
  .p-datatable-tbody {
    tr {
      .p-frozen-column:first-child {
        z-index: 1;
        background: #f5f5f5;
      }
    }
  }
  .p-datatable-wrapper::-webkit-scrollbar {
    width: 11px !important; /* Width of the scrollbar */
    height: 11px !important; /* Height for horizontal scrollbar */
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-track {
    background: #f5f5f5; /* Track background color */
    border-radius: 10px!important; /* Rounded corners */
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background: #8b8b8b; /* Scroll thumb color */
    border-radius: 10px !important;
  }
  
  .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
    //background: #8f8f8f; /* Thumb color on hover */
    background-color: var(--primary-color);
  }
  
  /* Firefox scrollbar styling */
  // .p-datatable-wrapper {
  //   scrollbar-width:5px;
  //   //height: 10px;
  //   scrollbar-color: #b1b1b1 #f5f5f5;
  // }
}
.shift-column {
  position: relative;
  vertical-align: baseline;
  .scheduler-cell-body {
    display: flex;
    /* height: 100%; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 5px;
  }
}

.workTimeScheduleHeader {
  vertical-align: top;

  .p-column-header-content {
    flex-direction: column;
  }
  .p-column-title {
    width: 100%;
  }
}

.employee-count-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reservation {
  background-color: var(--reservation-color);
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  margin-bottom: 5px;
  color: var(--blue-color);
  font-size: 11px;  

  .edit-headcount {
    &.edit {
      color: var(--blue-color);

      &:hover {
        color: hsl(from var(--blue-color) h s calc(l - 15));
      }
    }
  }
}

.no-events {
  background-color: hsl(from var(--white-color) h s calc(l - 8));
  //background-color: var(--white-color);
  color: hsl(from var(--secondary-color) h s calc(l + 40));
  text-align: center;
  font-weight: 500;
}
.add-reservation {
  //background-color: hsl(from var(--white-color) h s calc(l - 8));
  background-color: hsl(from var(--primary-color) h s calc(l + 45));
  //background-color: var(--white-color);
  color: var(--primary-color);
  text-align: center;
  margin-top: 5px;

  i {
    font-size: 18px;
  }

  &:hover {
    i {
      color: var(--secondary-color) !important;
    }
  }
}
.add-reservation-btn-only {
  color: var(--primary-color);
  font-size: 16px;

  &:hover {
    color: var(--secondary-color) !important;
  }
}
.work-schedule-card {
  &.p-card {
    box-shadow: none;
  }
  .p-card-body {
    //background-color: #f5f7f9;
    background: hsl(from var(--white-color) h s calc(l - 3));
    box-shadow: none;
    border: 1px dashed hsl(from var(--white-color) h s calc(l - 15));
    .p-card-content {
      padding: 0;
    }
  }
}

.border-bottom-dashed {
  border-bottom: 1px dashed hsl(from var(--white-color) h s calc(l - 9));
}

.calender-headcount-display {
  padding: 0rem 0rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-around;
  min-width: 70px;

  &.full-count {
    background-color: hsl(from var(--primary-color) h s calc(l + 40));
    color: var(--primary-color);

    .edit-headcount:hover {
      color: hsl(from var(--primary-color) h s calc(l - 20));
    }
  }
}

.less {
  background-color: hsl(from var(--red-color) h s calc(l + 28));
  color: var(--red-color);
}

.equal {
  background-color: hsl(from var(--green-color) h s calc(l + 54));
  color: var(--green-color);

  &:hover {
    .edit-headcount {
      color: hsl(from var(--green-color) h s calc(l - 10));
    }
  }
}

.more {
  background-color: hsl(from var(--primary-color) h s calc(l + 35));
  color: hsl(from var(--primary-color) h s calc(l - 15));

  &:hover i {
    color: hsl(from var(--primary-color) h s calc(l - 25));
  }
}

.edit-headcount {
  cursor: pointer;
  font-size: 14px;
}

.edit-headcount:hover {
  color: var(--red-color);
}

.scheduler-cell-body {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsl(from var(--white-color) h s calc(l - 4));
  background-color: hsl(from var(--white-color) h s calc(l - 2));
  border-radius: 3px;
  font-size: large;
  color: var(--secondary-color);
  //color: var(--green-color);
  cursor: pointer;
  transition: background-color 0.3s ease 0.25s;
}
.scheduler-cell-body:hover {
  background-color: hsl(from var(--white-color) h s calc(l - 6));
  transition-delay: 0s;
}

.small-add-button {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsl(from var(--white-color) h s calc(l - 4));
  background-color: hsl(from var(--white-color) h s calc(l - 2));
  border-radius: 3px;
  font-size: large;
  color: var(--secondary-color);
  //color: var(--green-color);
  cursor: pointer;
  transition: background-color 0.3s ease 0.25s;
}

.workschedule-date {
  font-size: 1rem !important;
  font-weight: 500;
}
.no-work-shift {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .title-nowork {
    color: hsl(from var(--secondary-color) h s calc(l + 65));
  }
  i {
    font-size: 2.5rem;
    color: hsl(from var(--secondary-color) h s calc(l + 65));
  }
}

.staff-availability-chip {
  background-color: hsl(from var(--primary-color) h s calc(l + 40));
  font-size: 0.8rem;
  color: var(--secondary-color);
  padding: 0.5rem;
  border-radius: 1rem;
  margin: 0 1rem 0 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  i {
    color: var(--primary-color);
  }
}

.staff-availability-info {
  padding-right: 0.3rem;
}

.holiday {
  background-color: hsl(from var(--red-color) h s calc(l + 31)) !important;
  color: hsl(from var(--red-color) h s calc(l + 5)) !important;
}

.max-height {
  height: max-content;
}

.mandatory-day {
  background-color: var(--mandatory-day-color);
  color: var(--primary-color);

  .help-main {
    color: var(--primary-color) !important;
    font-size: 1.2rem;

    &:hover {
      color: hsl(from var(--primary-color) h s calc(l - 5)) !important;
    }
  }

  &.small-add-button {
    color: var(--primary-color);
  }
}

.holiday-header {
  //background-color: hsl(from var(--light-red-color) h s calc(l + 25)) !important;
  background-color: var(--light-red-color) !important;

  .help-main {
    color: var(--red-color) !important;
    font-size: 1.2rem;

    &:hover {
      color:var(--red-color) !important;
    }
  }
}

.scheduler-shift-card-body {
  cursor: pointer;
  background-color: hsl(from var(--blue-color) h s calc(l + 30));
  color: var(--secondary-color);
  border-radius: 3px;
  margin: 0.2rem 0 0.2rem 0;
  padding: 0.2rem;
  font-size: 0.9rem;
  font-weight: 500;

  .shift-name-main {
    line-height: 18px;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
  }

  .shift-role {
    text-transform: capitalize;
    font-weight: bold;
    padding: 0 7px 0 7px;
    margin-right: 0.5rem;
    background-color: var(--white-color);
    border-radius: 3px;
    min-width: 25px;
    text-align: center;
  }

  .shift-name {
    text-transform: capitalize;
  }

  .shift-timing {
    // Styles specific to the shift timing span
  }
}

.scheduler-shift-card-body-not-available {
  cursor: pointer;
  background-color: hsl(from var(--red-color) h s calc(l + 30));
  color: var(--red-color);
  border-radius: 3px;
  margin: 0.2rem 0 0.2rem 0;
  padding: 0.2rem;
  font-size: 0.9rem;
  font-weight: 500;

  .shift-name-main {
    line-height: 18px;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
  }

  .shift-role {
    text-transform: capitalize;
    font-weight: bold;
    padding: 0 7px 0 7px;
    margin-right: 0.5rem;
    background-color: var(--white-color);
    border-radius: 3px;
    min-width: 25px;
    text-align: center;
  }

  .shift-name {
    text-transform: capitalize;
  }

  .shift-timing {
    // Styles specific to the shift timing span
  }
}

.schedule-title-badge {
  border-radius: 30px;
  // background: var(--light-red-color);
  // color: var(--light-red-color);
  font-size: 13px;
  font-weight: 500;
  padding: 0 0.9rem;
}
.custom-schedule-calendar {
  .ant-picker-input {
    input {
      line-height: 2.5 !important;
    }
  }
}

.my-schedule {
  &.p-datatable {
    .p-datatable-tbody {
      tr {
        td {
          border: none;
          &.staff-schedule-card {
            border: 0.5rem solid white !important;
          }
        }
      }
    }
  }
}

.filter-button-mobile {
  //background-color: #CCF1CE;
  //width: 3rem;
  .p-dropdown-label-empty {
    display: none;
  }
}

// CUSTOM TOOLTIP - START
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  /* Change cursor to pointer when hovering over the tooltip */
}

.custom-tooltip .tooltip-content {
  visibility: hidden;
  /* Initially hide the tooltip content */
  position: absolute;
  background-color: white;
  /* Background color for the tooltip */
  box-shadow: 0px 0px 6px 3px rgba(180, 180, 180, 1);
  padding: 8px;
  /* Padding around the tooltip content */
  border-radius: 4px;
  /* Border radius to round the corners of the tooltip */
  z-index: 999;
  /* Ensure tooltip is above other content */
  top: 100%;
  /* Position the tooltip below the trigger element */
  left: 50%;
  /* Center the tooltip horizontally relative to the trigger element */
  transform: translateX(-50%);
  /* Center the tooltip horizontally */
  min-width: 200px;
  /* Set a minimum width for the tooltip */
  max-width: 350px;
  /* Set a maximum width for the tooltip */
}

.custom-tooltip:hover .tooltip-content {
  visibility: visible;
  /* Show the tooltip content when hovering over the trigger element */
}

.tooltip-divider {
  margin: 8px 0;
  /* Adjust the margin as needed */
  border: none;
  border-top: 2px solid black;
  /* Style the horizontal line */
}

.tooltip-role {
  display: block;
}

.tooltip-name {
  display: block;
}

.tooltip-timing {
  display: block;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
}

.tooltip-row {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  /* Adjust spacing between rows */
}

.tooltip-label {
  font-weight: bold;
  margin-right: 5px;
  /* Adjust spacing between label and separator */
}

.tooltip-separator {
  margin-right: 5px;
  /* Adjust spacing between separator and value */
}

.tooltip-value {
  text-transform: capitalize;
}

// CUSTOM TOOLTIP - END

.custom-tooltip:has(.scheduler-shift-card-body) {
  width: 100%;
}
.p-confirm-popup {
  .p-confirm-popup-message {
    margin-left: 0rem;
  }
}
.schedule-title-badge {
  &.p-badge-danger {
    background: hsl(from var(--red-color) h s calc(l + 25));
    color: var(--red-color);
  }
  &.p-badge-success {
    background: hsl(from var(--green-color) h s calc(l + 54));
    color: var(--green-color);
  }

  &.draft-badge{
    background: hsl(from var(--primary-color) h s calc(l + 40)) !important;
    color: var(--primary-color) !important;
  }
}

.added-events {
  background-color: hsl(from var(--table-violet) h s calc(l + 35));
  text-align: center;
  border-radius: 5px;
  font-size: 0.9rem;
  flex-grow: 1;
}

.available-all-day {
  background: hsl(from var(--green-color) h s calc(l + 54));
  color: var(--green-color) !important;

  &:hover{
    background: hsl(from var(--green-color) h s calc(l + 45)) !important;
  }
  i{
    background: transparent;
  }
}

.not-available {
  background: hsl(from var(--red-color) h s calc(l + 25));
  color: var(--red-color);
  &:hover{
    background: hsl(from var(--red-color) h s calc(l + 20)) !important;
  }
  i{
    background: none;
  }
}

.scheduler-header {
  // background-color: #f1f5f9;
  //  border: 1px solid rgb(229, 229, 229);
  padding: 0.3rem 0.5rem;
  border-radius: 0px;
  //font-weight: 600;
}

.role-based-header-container {
  display: grid; /* to show the bottom border till the end */
  width: auto;
  position: sticky;
  top: 0; /* Stick to the top of the scrolling container */
  z-index: 10; /* Ensure it's above other content */
  border-bottom: 1px solid var(--secondary-violet); // problem
}

.role-based-table-list {
  th {
    padding: 0rem !important;
  }
}

.role-based-table-title {
  background: var(--role-based-view-color);
  padding: 0.5rem;
}

.role-based-table {
  .p-datatable-wrapper {
    overflow: none !important;
  }
}

.role-based-main-container {
  color: #000 !important;
  .work-time-schedule-table {
    &.p-datatable {
      .p-datatable-thead {
        tr {
          th {
            background: #ffffff;
            border-left: 2px solid #fff;
            color: #000;
          }
        }
      }
      .p-datatable-tbody > tr {
        color: #000 !important;
      }
    }
    thead {
      box-shadow: none !important;
    }
  }
  .no-events {
    color: #000;
  }
  .role-based-table-title {
    color: #000;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
  }
  .scheduler-shift-card-body {
    background-color: #ffff !important;
    border: 2px solid;
    border-color: #d3e3fb !important;
    color: #000000 !important;
  }
  .date-role-based {
    font-weight: 600;
  }
}

.work-time-schedule-table {
  .p-datatable-wrapper {
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
}

.print-icon {
  font-size: 1.5rem;
  color: var(--primary-color);

  &:hover {
    color: var(--primary-violet);
    cursor: pointer;
  }
}

.error-text-schedule {
  color: var(--secondary-violet);
  font-weight: 500 !important;
  // Role Based Page Styles
}

.time-off {
  &.p-badge-danger {
    background: var(--light-red-color);
    color: black;
  }
}
.mandatory-bg {
  .accordion-button {
    background-color: var(--mandatory-day-color-schedule);
  }
}
.holiday-bg {
  .accordion-button {
    background-color: var(--light-red-color);
  }
}

/*---event card----*/
.event-card-scroll {
  max-height: 60px;
  overflow-y: auto;
  padding-right: 2px;
  //scrollbar-color: #ff0000 #000dff; /* For Firefox */
  //scrollbar-width: 5px;

  &::-webkit-scrollbar-track {
    background: #fbfbfc; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8ebbff; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounds the scrollbar handle */
    border: 5px solid #8ebbff; /* Adds some padding to the handle */
    width: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Changes color on hover */
  }


}

.scheduler-arrow{
  font-size: 2rem;
  font-weight: 600;
  color: var(--secondary-color);
  //color: var(--primary-color);


  &:hover{
    cursor: pointer;
    opacity: 0.8;
    ;
  }
}


/*-------work time schedule-------*/
.manage-time-off{
  margin-bottom: 1rem;
  // display: flex;
  display: grid;
  align-items: flex-end;
  justify-content: flex-end;
  //border-bottom: 1px solid var();
  
  div{
      padding: 0.15rem;
      --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-6));
      border-bottom: 1px dashed hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
      font-weight: 600;
      cursor: pointer;

      &:hover{
        --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
        --adjusted-lightness-n: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-6));
        color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness));
        border-bottom: 1px dashed hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness-n)); 
      }
    }

    i{
      margin-right: 0.5rem;
      font-weight: normal;
      --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-4));
      color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness));
    }
}

.unavailable{
  z-index: 1;
}



.ant-color-picker-presets{
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.conflict-card{
  background: #ffffff;
    padding: 0.5rem;
    border: 1px dashed #b7b7b7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

  i{
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .username{
    font-weight: 500;
  }
}
.schedule-scroll{
  height: 100%;
  .p-tabview-panels{
   height: 100%;
  }
}

.cldr-action-icon {
  i {
    font-size: 1.2rem;
    //color:var(--secondary-color);
    margin: 0.8rem;
    --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-3)) !important;
    color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important;

    &:hover {
      --adjusted-lightness-1: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-1)) !important;
      color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness-1)) !important;
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
.cldr-action-icon-large{
  i {
    font-size: 1.5rem;
    //color:var(--secondary-color);
    margin: 0.6rem;
    --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-3)) !important;
    color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important;

    &:hover {
      --adjusted-lightness-1: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-1)) !important;
      color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness-1)) !important;
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.publish-button {
  background-color: var(--success-color);
  border-color: var(--success-color) !important;

  &:hover {
    background: hsl(
      from var(--success-color) h s calc(l - 10)
    ) !important; //for light background
  }
}

.card-header {
  .p-button {
    //padding: 0.55rem 1.25rem;
  }

  .publish-button {
    // padding: 0.75rem 1.25rem;
    &.ewts-published{
      &.p-disabled{
        --adjusted-lightness-B: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-4)) !important;
        background-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness-B)) !important;
        border: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness-B)) !important;
        cursor:no-drop;
      }
    }
   
  }
}

.search-dropdown{
  &.p-inputwrapper-filled{
    background-color: var(--primary-color);

    .p-multiselect-trigger {
      color: #fff !important;
    }
  }
}