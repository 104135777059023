@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";

.survey-question-outer {
  background: #f5f5f5;
  padding: 1rem;
}
.drag-ic {
  &:hover {
    cursor: move;

    i {
      transition: all 0.3s;
      color: rgb(70, 70, 70);
    }
  }

  i {
    font-size: 1.5rem;
    color: #ccc;
  }
}

.categoryBox-header {
  padding: 0.5rem;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}
.category-left-option {
  span {
    &:nth-child(2) {
      margin-left: 1.5rem;
    }
  }
}

.nxt-btn {
  button {
    min-width: 200px;
    margin-right: 0.5rem;

    i {
      margin-left: 0.5rem;
    }
  }
}

.question-box-outer {
  background-color: #fff;
  border-radius: 5px;
  //display: flex;
  flex: 100% 1;
  word-break: break-all;
  align-items: center;
  height: 100%;
  transition: all 0.2s;
}

.star-rating {
  i {
    color: var(--primary-color);
    font-size: 1.5rem;
    padding-right: 1.2rem;
  }

  i:last-child {
    padding-right: 0rem;
  }
}

.survey-position {
  position: relative;
  //margin-bottom: 4rem;
}

.survey-btn-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding-top: 1rem;
  right: 0;
  z-index: 1;
}

h5 {
  &.qus-category {
    display: flex;
    align-items: center;
    font-weight: 600;

    &:after {
      content: "";
      flex: 1;
      margin-left: 1rem;
      height: 1px;
      border-bottom: 2px dotted hsl(from var(--secondary-color) h s calc(l + 20));
      opacity: 0.4;
      //background-color: var(--font-color);
    }
  }
}
.delete-round {
  position: absolute;
  background: var(--danger-color);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -5px;
  i {
    font-size: 1rem;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.survey-matrix {
  thead {
    background-color: #e0e0e0;

    tr {
      background-color: #e0e0e0;
      border-bottom: 2px solid #c9c9c9;

      th {
        position: relative;
      }
    }
  }

  tr {
    background-color: #fff;

    th {
      position: relative;
    }
  }
  &.table-fixed {
    thead {
      tr {
        th {
          border-bottom: 2px solid #c9c9c9;
        }
      }
    }
  }
}

.top-delete {
  position: absolute;
  top: -2rem;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 1.5rem;
  color: var(--red-color);
}
.side-delete {
  position: absolute;
  //left: -2rem;
  left: -18px;
  margin: auto 0;
  text-align: center;
  font-size: 1.5rem;
  color: var(--red-color);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.rating-result {
  min-width: 100px;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;

  &.first {
    background-color: var(--red-color);
  }
  &.second {
    background-color: var(--side-menu-icn-color);
  }
  &.third {
    background-color: var(--dashboard-green-2);
  }
  &.fourth {
    background-color: var(--green-color);
  }
  &.fifth {
    background-color: var(--primary-color);
  }
}
.star-rating-outer {
  justify-content: end;
  display: flex;
}

.survey-header {
  > .flex-grow-1 {
    padding-left: 0 !important;
  }
}

.sticky-add-btn {
  position: sticky;
  top: -15px;
  z-index: 9;
  //padding-top: 11px;
}

/*---matrix table---*/
$table-border: #d9d9d9;
$row-bg: #f4f2f1;

.table-container {
  overflow: hidden;
}

.table-scroll {
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.flag-icon {
  margin-right: 0.1em;
}

.flex-table {
  display: flex;
  flex-flow: row nowrap;
  border-left: solid 1px $table-border;
  transition: 0.5s;
  &:first-of-type {
    //border-top: solid 1px $table-header-border;
    //border-left: solid 1px $table-header-border;
  }
  &:first-of-type .flex-row-new {
    //background: var(--secondary-color);
    background: #b8b8b8;
    color: var(--font-color);
    border-color: lighten(#b8b8b8, 40);
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
  }

  &.row:nth-child(odd) .flex-row-new {
    //background: $row-bg;
  }
  &:hover {
    //background: #F5F5F5;
    transition: 500ms;
  }
}

.flex-row-new {
  width: calc(100% / 4);
  text-align: center;
  padding: 0.7em 0.5em;
  border-right: solid 1px $table-border;
  border-bottom: solid 1px $table-border;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;

  &.first {
    font-weight: 500;
    background: #ebebeb;
  }
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row-new {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;
    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }
}

.flex-cell {
  width: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
  &:last-child {
    // border-right: 0;
  }
}

@media all and (max-width: 767px) {
  .flex-row-new {
    width: calc(100% / 3); //1px = border right
    min-width: 150px;

    &.first {
      width: 100%;
    }
  }

  .column {
    width: 100%;
  }

  .flex-table {
    flex-flow: row nowrap;
  }
}

@media all and (max-width: 430px) {
  .flex-table {
    .flex-row-new {
      border-bottom: solid 1px $table-border;
    }
    .flex-row-new:last-of-type {
      border-bottom: solid 1px $table-border;
    }
  }

  .header {
    .flex-row-new {
      border-bottom: solid 1px;
    }
  }

  .flex-row-new {
    width: 100%; //1px = border right

    &.first {
      width: 100%;
      border-bottom: solid 1px $table-border;
    }
  }

  .column {
    width: 100%;
    .flex-row-new {
      border-bottom: solid 1px $table-border;
    }
  }

  .flex-cell {
    width: 100%; //1px = border right
  }
}
