
/*-----custom button-----*/

.btn-custom-primary{
  background-color:var(--primary-color) !important;
  color: var(--white-color) !important;
  border-color: var(--primary-color) !important;
  font-family: 'Poppins', sans-serif !important;

  &.btn-lg{
      font-size: var(--button-font-lg);
      padding: 1rem;
      border-radius: var(--rd-5px);
      font-weight: 600;
  }
  &:focus {
      background-color: var(--primary-color) !important;
      border-color:  var(--primary-color) !important;
       // @include box-shadow-primary-custom;
      box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + 0.45)) !important;
    }

    &:hover {
      background-color: hsl(from var(--primary-color) h s calc(l - 4)) !important;
      border-color:  var(--primary-color) !important;
      color: var(--white-color) !important;
     
      
    }
    &:active{
      border-color:  var(--primary-color) !important;
      background-color: hsl(from var(--primary-color) h s calc(l - 4)) !important;

      &:focus{
        @include box-shadow-primary-custom;
      }
    }

    &:disabled{
      // box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + .2));
      // background-color: hsl(from var(--primary-color) h s calc(l + .4));
       background-color: hsl(from var(--primary-color) h s calc(l + 20)) !important;
       border: 1px solid hsl(from var(--primary-color) h s calc(l + .5)) !important;
       opacity: .85;
       cursor: not-allowed;
       pointer-events: auto;
     }
}

.btn-custom-secondary{
  background-color:var(--secondary-color) !important;
  color: var(--white-color) !important;
  border-color: var(--secondary-color) !important;
  font-family: 'Poppins', sans-serif !important;

  &.btn-lg{
      font-size: var(--button-font-lg);
      padding: 1rem;
      border-radius: var(--rd-5px);
      font-weight: 600;
  }
  &:focus {
      background-color: var(--secondary-color) !important;
      border-color:  var(--secondary-color) !important;
       // @include box-shadow-primary-custom;
      box-shadow: 0 0 0 0.25rem hsl(from var(--secondary-color) h s calc(l + 0.45)) !important;
    }

    &:hover {
      background-color: hsl(from var(--secondary-color) h s calc(l - 4)) !important;
      border-color:  var(--secondary-color) !important;
      color: var(--white-color) !important;
     
      
    }
    &:active{
      border-color:  var(--secondary-color) !important;
      background-color: hsl(from var(--secondary-color) h s calc(l - 4)) !important;

      &:focus{
        @include box-shadow-secondary-custom;
      }
    }

    &:disabled{
      // box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + .2));
      // background-color: hsl(from var(--primary-color) h s calc(l + .4));
       background-color: hsl(from var(--secondary-color) h s calc(l + 20)) !important;
       border: 1px solid hsl(from var(--secondary-color) h s calc(l + .5)) !important;
       opacity: .85;
       cursor: not-allowed;
       pointer-events: auto;
     }
}


/*--admin page--*/ 
.btn-custom-primary-outline{
  // background-color: var(--primary-color);
   color: var(--primary-color) !important;
   border-color: var(--primary-color) !important;
   background-color: var(--white-color) !important;
   //font-family: 'Poppins', sans-serif;

   &.btn-lg{
       font-size: var(--button-font-lg);
       padding: 1rem;
       border-radius: var(--rd-5px);
       font-weight: 600;
   }
   &:focus {
       background-color: var(--primary-color);
       border-color:  var(--primary-color);
       @include box-shadow-primary-custom;
       color: var(--white-color);
     }
 
     &:hover {
      background-color:  hsl(from var(--primary-color) h s calc(l + 45)) !important;
       border-color:  var(--primary-color) !important;
       color: var(--primary-color) !important;
      
       
     }
     &:active{
       border-color:  var(--primary-color);

       &:focus{
         @include box-shadow-primary-custom;
       }
     }
}

.btn-custom-secondary-outline{
  // background-color: var(--primary-color);
   color: var(--secondary-color) !important;
   border-color: var(--secondary-color) !important;
   background-color: var(--white-color) !important;
   //font-family: 'Poppins', sans-serif;

   &.btn-lg{
       font-size: var(--button-font-lg);
       padding: 1rem;
       border-radius: var(--rd-5px);
       font-weight: 600;
   }
   &:focus {
       background-color: var(--secondary-color);
       border-color:  var(--secondary-color);
       @include box-shadow-primary-custom;
       color: var(--white-color);
     }
 
     &:hover {
      background-color:  hsl(from var(--primary-color) h s calc(l + 45)) !important;
       border-color:  var(--secondary-color) !important;
       color: var(--secondary-color) !important;
      
       
     }
     &:active{
       border-color:  var(--primary-color);

       &:focus{
         @include box-shadow-primary-custom;
       }
     }
}


.btn-lg-custom{
   font-size: 1.25rem;
   border-radius: 0.3rem; 
   //color: var(--primary-color); 
   //color: #fff;
}

.btn-check:focus+.btn{
 //@include box-shadow-custom;
 outline: 0;
 @include box-shadow-primary-custom;
}

/*------toggle----*/ 
.form-check-input-custom:checked {
   background-color: var(--primary-color);
   border-color: var(--primary-color);
}
.form-check-input-custom:focus {
 @include box-shadow-primary-custom;
 border-color: var(--primary-color);
}
/*.form-switch {

 .form-check-input:focus {
   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org…='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
 }
}*/

.form-switch .form-check-input:focus {
 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23cccccc'/%3e%3c/svg%3e");
}


.custom-control-input:checked ~ .custom-control-label::before {
 background-color: var(--primary-color);
 border-color:  var(--primary-color);
}





/*----drop down-----*/ 
.dropdown-item.active, .dropdown-item:active {
 
 background-color: var(--primary-color);
}

/*---primeReact btn theming---*/
.btn-prime-primary-outline{
  // background-color: var(--primary-color);
   color: var(--primary-color) !important;
   border: 1px solid var(--primary-color) !important;
   background-color: var(--white-color) !important;
   //font-family: 'Poppins', sans-serif;

   &.p-dropdown {
    .p-dropdown-trigger {
      background: transparent;
      color:  var(--primary-color);
   }
   }

   &.btn-lg{
       font-size: var(--button-font-lg);
       padding: 1rem;
       border-radius: var(--rd-5px);
       font-weight: 600;
   }
   &:focus {
       background-color: var(--primary-color);
       border-color:  var(--primary-color);
       @include box-shadow-primary-custom;
       color: var(--white-color);
     }
 
     &:hover {
      background-color:  hsl(from var(--primary-color) h s calc(l + 45)) !important;
       border-color:  var(--primary-color) !important;
       color: var(--primary-color) !important;
      
       
     }
     &:active{
       border-color:  var(--primary-color);

       &:focus{
         @include box-shadow-primary-custom;
       }
     }
}
.p-button-primary{
  // background: var(--primary-color);
  // border: 1px solid hsl(from var(--primary-color) h s calc(l - 2));

  // &:enabled{
  //   &:focus{
  //     box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px hsl(from var(--primary-color) h s calc(l - 1)), 0 1px 2px 0 black;
  //   }
  // }
}
