.shift-schedule-sidebar-form {
    .availability-form-accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1.05rem;

                    .staff-availability-chip {
                        padding: 0.25rem;
                        border-radius: 5px;
                        margin-left: auto;
                    }

                    .p-badge {
                        margin-left: auto;
                    }

                    &::after {
                        margin-left: 10px;
                    }
                }
            }

            .accordion-body {
                .manage-time-off {
                    margin-bottom: 0.15rem;
                }
                .work-schedule-card {
                    .p-card-body {
                        padding: 0px;
                        font-size: 0.875rem;
                        border-radius: 3px;

                        .p-card-content {
                            .shift-dropdown-role {
                                padding: 0.45rem 0.75rem 0rem 0.75rem;
                            }
                            .p-dropdown {
                                border-radius: 3px;
                                .p-dropdown-label {
                                    padding: 0.25rem 0.75rem;
                                    font-size: 0.875rem;
                                }
                            }
                            .antD-shiftcard {
                                padding: 0.25rem 0.75rem;
                            }
                            .shift-dropdown-role-body {
                                background-color: #fff;
                                padding: 0.45rem 0.75rem 0.5rem 0.75rem;
                                border-top: 1px dashed hsl(from var(--white-color) h s calc(l - 15));
                                .p-button {
                                    padding: 0.25rem 0.75rem;
                                    border-radius: 3px;
                                }
                                .antD-shiftcard {
                                    max-width: 100px;
                                }
                            }
                            .p-inputswitch {
                                width: 2.5rem;
                                height: 1.15rem;
                                .p-inputswitch-slider {
                                    &::before {
                                        width: 0.85rem;
                                        height: 0.85rem;
                                        left: 0.15rem;
                                        margin-top: -0.425rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .shift-card-body {
                    max-height: 30rem;
                    overflow-y: auto;
                    padding: 5px 10px;
                }
                .shift-card-footer {
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   background: #f7f7f7;
                }
                .add-shift-btn {
                    font-weight: 600;
                    padding: 0px 8px;
                    .ant-btn-icon {
                        margin-right: 5px;
                        i {
                            color: var(--primary-color);
                            font-size: 1.05rem;
                        }
                    }
                    &:hover {
                        color: var(--primary-color) !important;
                        border-color: var(--primary-color) !important;
                    }
                }
            }
        }
    }
}
