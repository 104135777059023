.custom-week-filter {
    .left-icon-btn,
    .right-icon-btn,
    .home-icon-btn {
        min-width: 40px;
        padding: 1px 6px;
        .btn-text {
            font-size: 20px;
        }

        &:focus {
            --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-6)) !important;
            box-shadow: none !important;
            border-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important; 
        }
    }
    .custom-week-picker {
        padding: 0.365rem 0.5rem;
        .ant-picker-suffix,
        .ant-picker-clear {
            font-size: 1.35rem;
            margin: 0px !important;

             &:hover {
                margin: 0px !important;
                color: var(--primary-color);
            }
        }
    }
    .right-icon-btn, .left-icon-btn{
        --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-6)) !important;
        border-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important; 

        &:hover{
            border-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important;   
        }
    }
}

// Clear selection on picker panel change
.custom-week-picker-panel-changed {
    .ant-picker-cell-selected {
        .ant-picker-cell-inner {
            color: rgba(0, 0, 0, 0.88) !important;
            background: #fff !important;
        }
    }
    .ant-picker-week-panel-row-selected {
        .ant-picker-cell:before {
            background: #fff !important;
        }
        .ant-picker-cell-inner {
            color: rgba(0, 0, 0, 0.88) !important;
        }
        .ant-picker-cell-week {
            .ant-picker-cell-inner {
                color: rgba(0, 0, 0, 0.25) !important;
            }
        }
    }
}